import React, { useState, useEffect } from "react"
import ReactSwitch from "react-switch"
import axios from "axios"
import { ContentWrapper } from "./Form"
import styled from "@emotion/styled"
import { Toaster } from "react-hot-toast"
import toast from "react-hot-toast"
import AuthService from "../services/auth.service"

export default function SmsToggle({ userId, setIsSubscribed, isSubscribed }) {
	const SMS_URL = "https://cocky-kowalevski-87b5ff.netlify.app/.netlify/functions/server/sms/"
	const [message, setMessage] = useState("")

	let handleSubscribe = (isSubscribed) => {
		let user = AuthService.getCurrentUser()

		axios
			.post(SMS_URL + userId, {
				userId: userId,
				isSubscribed: isSubscribed,
			})
			.then((response) => {
				user.data.info = isSubscribed
				AuthService.updateSms(user)
				toast(message)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	useEffect(() => {
		let message = isSubscribed
			? "Nećete primati obaveštenja o zakazanim terminima preko SMS-a."
			: "Uspešno ste se prijavili ste se na SMS servis."
		setMessage(message)
	}, [isSubscribed])

	return (
		<ContentWrapper>
			<Toaster reverseOrder={true} position="top-center" />
			<SwitchWrapper>
				<div>
					<Heading>{"SMS Servis"}</Heading>
					<SubHeading>
						{isSubscribed ? "Prijavljeni ste na SMS servis" : "Odjavljeni ste sa SMS servisa"}
					</SubHeading>
				</div>
				<ReactSwitch
					onColor="#24b7be"
					onHandleColor="#24b7be"
					handleDiameter={30}
					uncheckedIcon={false}
					checkedIcon={false}
					boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
					activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
					height={20}
					width={48}
					checked={isSubscribed === 1}
					onChange={() => {
						setIsSubscribed((prevValue) => (prevValue === 0 ? 1 : 0))
						handleSubscribe(isSubscribed ? 0 : 1)
					}}
				/>
			</SwitchWrapper>
		</ContentWrapper>
	)
}

let SwitchWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

let HeadingWrapper = styled.div`
	display: flex;
	flex-direction: column;
`

let Heading = styled.h5`
	font-size: 14px;
	font-weight: 700;
	color: var(--dark);
	margin-bottom: 4px;
`
let SubHeading = styled.h6`
	font-size: 12px;
	font-weight: 400;
	color: #9f9f9f;
`
