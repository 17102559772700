import axios from "axios"

const API_URL = "https://cocky-kowalevski-87b5ff.netlify.app/.netlify/functions/server/auth"

class AuthService {
	login(id, prezime) {
		return axios
			.post(API_URL, {
				id,
				prezime,
			})
			.then((response) => {
				if (response.data.accessToken) {
					localStorage.setItem("user", JSON.stringify(response.data))
				}

				return response.data
			})
	}

	logout() {
		localStorage.removeItem("user")
	}

	register(username, email, password) {
		return axios.post(API_URL + "signup", {
			username,
			email,
			password,
		})
	}

	getCurrentUser() {
		return JSON.parse(localStorage.getItem("user"))
	}

	updateSms(user) {
		return localStorage.setItem("user", JSON.stringify(user))
	}
}

export default new AuthService()
