import Form from "./components/Form"

function App() {
	return (
		<div className="App">
			<Form></Form>
		</div>
	)
}

export default App
