import styled from "@emotion/styled"
import { useEffect, useState } from "react"
import AuthService from "../services/auth.service"
import Logo from "./Logo"
import axios from "axios"
import moment from "moment"
import "moment/locale/sr"
import NewPassword from "./NewPassword"
import SmsToggle from "./SmsToggle"
import PaymentsBox from "./PaymentsBox"

const BASE = "https://cocky-kowalevski-87b5ff.netlify.app/.netlify/functions/server"
const APPOINTMENTS_URL = BASE + "/zakazivanja/"
const PAYMENTS_URL = BASE + "/placanja/"
const GENERAL_URL = BASE + "/gen/"

export default function Form() {
	let [isLoading, setIsLoading] = useState(false)
	// login
	let [userId, setUserId] = useState("")
	let [password, setPassword] = useState("")
	let [notFound, setNotFound] = useState(false)
	// user
	let [currentUser, setCurrentUser] = useState(null)
	let [isSubscribed, setIsSubscribed] = useState(null)
	let [isFirstTime, setIsFirstTime] = useState(0)
	// data
	let [payments, setPayments] = useState(null)
	let [appointments, setAppointments] = useState(null)
	let [generalPayments, setGeneralPayments] = useState(null)

	let valid = password?.length > 1 && userId !== ""

	useEffect(() => {
		moment.locale("sr")
	}, [])

	let handleLogin = () => {
		setIsLoading(true)

		AuthService.login(userId, password).then(
			(user) => {
				setNotFound(false)
				setIsSubscribed(user.data.info)
				setCurrentUser(user)
				setIsFirstTime(user.data.first_login)
				getAppointments(user)
				getPayments(user)
				getGeneralPayments(user)
			},
			(error) => {
				setNotFound(true)
				setIsLoading(false)
			},
		)
	}

	let handleInvalidToken = (error) => {
		if (error.response?.status !== 401) {
			return
		}

		AuthService.logout()
		setPassword("")
		setCurrentUser(null)
	}

	let getPayments = (user) => {
		setIsLoading(true)

		if (!user) {
			return
		}

		axios
			.get(PAYMENTS_URL + user.data.ID, {
				headers: {
					Authorization: `Bearer ${user.accessToken}`,
				},
			})
			.then((response) => {
				setPayments(response.data)
				setIsLoading(false)
			})
			.catch((error) => {
				setIsLoading(false)
			})
	}

	let getGeneralPayments = (user) => {
		setGeneralPayments(null)
		setIsLoading(true)

		if (!user) {
			return
		}

		axios
			.get(GENERAL_URL + user.data.ID, {
				headers: {
					Authorization: `Bearer ${user.accessToken}`,
				},
			})
			.then((response) => {
				setGeneralPayments(response.data)
				setIsLoading(false)
			})
			.catch((error) => {
				handleInvalidToken(error)
				setIsLoading(false)
			})
	}

	let getAppointments = (user) => {
		setIsLoading(true)
		setAppointments(null)

		if (!user) {
			return
		}

		axios
			.get(APPOINTMENTS_URL + user.data.ID, {
				headers: {
					Authorization: `Bearer ${user.accessToken}`,
				},
			})
			.then((response) => {
				setAppointments(response.data)
				setIsLoading(false)
			})
			.catch(function (error) {
				setIsLoading(false)
			})
	}

	let handleLogout = () => {
		AuthService.logout()
		setUserId("")
		setPassword("")
		setPayments(null)
		setCurrentUser(null)
		setGeneralPayments(null)
	}

	useEffect(() => {
		setAppointments(null)

		const currentUser = AuthService.getCurrentUser()

		if (!currentUser) {
			return
		}

		setIsFirstTime(currentUser.data.first_login)
		setIsSubscribed(currentUser.data.info)
		setCurrentUser(currentUser)

		getAppointments(currentUser)
		getPayments(currentUser)
		getGeneralPayments(currentUser)
	}, [])

	useEffect(() => {
		let body = document.getElementsByTagName("body")

		if (!currentUser) {
			body[0].style.overflow = "hidden"
		} else {
			body[0].style.overflow = "auto"
		}
	}, [currentUser])

	if (isLoading) {
		return (
			<LoaderWrapper>
				<Loader />
			</LoaderWrapper>
		)
	}

	if (currentUser) {
		return isFirstTime ? (
			<NewPassword
				setIsLoading={setIsLoading}
				userId={currentUser.data.ID}
				setIsFirstTime={setIsFirstTime}
				handleLogin={handleLogin}
				getAppointments={getAppointments}
				getPayments={getPayments}
				getGeneralPayments={getGeneralPayments}
			/>
		) : (
			<Background>
				<FullContent>
					<Logo />
					<SmsToggle
						isSubscribed={isSubscribed}
						setIsSubscribed={setIsSubscribed}
						userId={currentUser.data.ID}
					/>
					<ContentWrapper>
						<Info>
							<Heading>Informacije o korisniku:</Heading>
							<Grid>
								<InputWrapper>
									<Input disabled={true} value={currentUser.data.ime} />
									<Label isActive={true}>Ime</Label>
								</InputWrapper>
								<InputWrapper>
									<Input disabled={true} value={currentUser.data.prezime} />
									<Label isActive={true}>Prezime</Label>
								</InputWrapper>
								<InputWrapper>
									<Input disabled={true} value={moment(currentUser.data.datumr).format("LL")} />
									<Label isActive={true}>Datum rođenja</Label>
								</InputWrapper>
								<InputWrapper>
									<Input disabled={true} value={currentUser.data.brtel} />
									<Label isActive={true}>Broj telefona</Label>
								</InputWrapper>
							</Grid>
						</Info>
					</ContentWrapper>
					{appointments && (
						<ContentWrapper>
							<Heading>Zakazno za:</Heading>
							<InputWrapper>
								<Input disabled={true} value={moment(appointments.datum).format("LL")} />
								<Label isActive={true}>Datum</Label>
							</InputWrapper>
							<InputWrapper>
								<Input disabled={true} value={appointments.vreme} />
								<Label isActive={true}>Vreme</Label>
							</InputWrapper>
						</ContentWrapper>
					)}
					{generalPayments && payments && (
						<PaymentsBox generalPayments={generalPayments} payments={payments} />
					)}
					<ContentWrapper>
						<SubmitButton onClick={handleLogout}>Izloguj se</SubmitButton>
					</ContentWrapper>
				</FullContent>
			</Background>
		)
	}

	return (
		<Background>
			<ContentBox>
				<Logo />
				<LoginForm
					autoComplete="on"
					onSubmit={(e) => {
						e.preventDefault()
						handleLogin()
					}}
				>
					<InputWrapper>
						<Input
							id="username"
							type="text"
							value={userId}
							onInput={(e) => {
								setUserId(e.target.value)
							}}
						/>
						<Label htmlFor="username" isActive={userId?.length > 0}>
							Korisnicki ID
						</Label>
					</InputWrapper>
					<InputWrapper>
						<Input
							type="password"
							id="password"
							value={password}
							onInput={(e) => {
								setPassword(e.target.value)
							}}
						/>
						<Label htmlFor="password" isActive={password?.length > 0}>
							Lozinka
						</Label>
					</InputWrapper>
					{notFound && <NotFound>Korisnik ne postoji</NotFound>}
					<SubmitButton type="submit" disabled={!valid}>
						Uloguj se
					</SubmitButton>
				</LoginForm>
			</ContentBox>
		</Background>
	)
}

let LoaderWrapper = styled.div`
	background: #f3f3f3;
	min-height: 100vh;
	min-width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
`

let Loader = styled.div`
	width: 48px;
	height: 48px;
	border-radius: 50%;
	border: 2px solid transparent;
	border-left: 2px solid var(--dark);
	animation: rotate 600ms infinite;

	@keyframes rotate {
		0% {
			transform: rotate(0);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`

export let Background = styled.div`
	background: #f3f3f3;
`

let NotFound = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(234, 26, 26, 0.25);
	color: #ea1a1a;
	font-size: 14px;
	padding: 6px;
	font-weight: 600;
	margin-bottom: 20px;
	border-radius: 8px;
`

export let ContentBox = styled.div`
	max-width: 360px;
	margin: 0 auto;
	padding: 24px;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`

export let FullContent = styled.div`
	max-width: 800px;
	margin: 0 auto;
	padding: 24px;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;

	@media (max-width: 480px) {
		svg {
			max-width: 160px;
			height: auto;
		}
	}
`

let LoginForm = styled.form`
	padding: 24px;
	background: #fff;
	width: 100%;
	border-radius: 16px;
	box-shadow: 0 1px 2px 0 rgba(9, 30, 66, 0.25);
`
export let ContentWrapper = styled.div`
	width: 100%;
	padding: 24px;
	background: #fff;
	box-shadow: 0 1px 2px 0 rgba(9, 30, 66, 0.25);
	border-radius: 16px;
	margin-bottom: 24px;
`

let Info = styled.div`
	@media (max-width: 480px) {
		display: flex;
		flex-direction: column;
	}
`

export let Heading = styled.h4`
	margin: 0 0 24px 0;
	color: var(--dark);
`

let Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
	grid-gap: 24px;

	> div {
		margin-bottom: 0;
	}
`

export let InputWrapper = styled.div`
	position: relative;
	margin-bottom: 20px;
`

export let Input = styled.input`
	box-shadow: 0 0 0 0 rgba(30, 155, 161, 0.25);
	border: 1px solid #bdbdbd;
	border-radius: 8px;
	min-height: 40px;
	font-weight: 500;
	outline: none;
	padding: 8px 16px;
	transition: all 300ms;
	width: 100%;
	position: relative;
	z-index: 1;
	background: transparent;
	font-size: 14px;
	font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
		"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-appearance: none;
	color: var(--dark);
	-webkit-text-fill-color: var(--dark);
	opacity: 1;

	&:focus {
		box-shadow: 0 0 0 2px rgba(30, 155, 161, 0.25);
		border: 1px solid #24b7be;
	}

	&:disabled {
		box-shadow: 0 0 0 0 rgba(30, 155, 161, 0.25);
		border: 1px solid #bdbdbd;
		-webkit-appearance: none;
		color: var(--dark);
		-webkit-text-fill-color: var(--dark);
		opacity: 1;
	}

	&:focus + label {
		transform: translateY(-26px);
		z-index: 1;
		color: #1d97a0;
		font-size: 12px;
	}
`

export let Label = styled.label`
	color: var(--dark);
	padding: 0 4px;
	display: block;
	background: #fff;
	position: absolute;
	z-index: ${(props) => (props.isActive ? 1 : 0)};
	left: 12px;
	top: 50%;
	transform: ${(props) => (props.isActive ? "translateY(-26px)" : "translateY(-50%)")};
	line-height: 1;
	transition: all 300ms;
	transform-origin: left center;
	font-size: ${(props) => (props.isActive ? "12px" : "14px")};
	font-weight: 500;
`

export let SubmitButton = styled.button`
	border: none;
	outline: none;
	background: #24b7be;
	width: 100%;
	min-height: 40px;
	border-radius: 8px;
	font-size: 14px;
	font-weight: 500;
	color: #fff;
	cursor: pointer;
	transition: all 300ms;

	&:hover {
		background: var(--dark);
	}

	&:disabled {
		opacity: 0.6;
		pointer-events: none;
	}
`
