export default function Logo() {
	return (
		<svg width="260" height="130" viewBox="0 0 260 130" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M82.4243 55.5577C81.5577 55.5577 80.7733 55.3843 80.067 55.0377C79.3607 54.691 78.754 54.2187 78.2383 53.625C77.7227 53.0313 77.3283 52.3597 77.051 51.6143C76.7737 50.869 76.635 50.102 76.635 49.3177C76.635 48.4987 76.7823 47.7143 77.077 46.969C77.3717 46.2237 77.7833 45.5607 78.3077 44.9757C78.832 44.3907 79.4473 43.9313 80.1537 43.589C80.86 43.2467 81.627 43.0777 82.459 43.0777C83.3257 43.0777 84.11 43.2553 84.8163 43.615C85.5227 43.9747 86.125 44.4513 86.6363 45.0537C87.1433 45.656 87.5377 46.3233 87.815 47.0643C88.0923 47.8053 88.231 48.5593 88.231 49.335C88.231 50.154 88.088 50.9383 87.7977 51.6837C87.5073 52.429 87.1 53.092 86.5757 53.677C86.0513 54.262 85.436 54.7213 84.7297 55.055C84.0233 55.393 83.2563 55.5577 82.4243 55.5577ZM78.2123 49.322C78.2123 49.946 78.312 50.5483 78.5157 51.1333C78.7193 51.7183 79.0053 52.234 79.3737 52.6847C79.742 53.1353 80.1883 53.495 80.7083 53.7593C81.2283 54.0237 81.8047 54.158 82.4417 54.158C83.1003 54.158 83.6897 54.0193 84.2097 53.742C84.7297 53.4647 85.1717 53.0963 85.5357 52.6327C85.8997 52.169 86.177 51.649 86.3677 51.0727C86.5583 50.4963 86.6537 49.9113 86.6537 49.322C86.6537 48.698 86.554 48.1 86.3503 47.5193C86.1467 46.943 85.8607 46.4273 85.4837 45.968C85.1067 45.513 84.6647 45.1533 84.149 44.8933C83.6333 44.6333 83.0657 44.5033 82.4417 44.5033C81.783 44.5033 81.1937 44.6377 80.6737 44.9107C80.1537 45.1837 79.7117 45.5477 79.3477 46.0113C78.9837 46.475 78.702 46.9907 78.507 47.5627C78.312 48.1347 78.2123 48.7197 78.2123 49.322Z"
				fill="#383838"
			/>
			<path
				d="M90.3933 55.471V43.173H95.6583C96.2 43.173 96.6983 43.2857 97.149 43.511C97.5996 43.7363 97.994 44.0353 98.3276 44.4123C98.6613 44.7893 98.9213 45.2097 99.1076 45.6777C99.294 46.1457 99.385 46.6223 99.385 47.1077C99.385 47.6753 99.2766 48.2127 99.0643 48.7197C98.852 49.2267 98.5486 49.66 98.163 50.0197C97.7773 50.3793 97.3223 50.6263 96.8023 50.765L99.7836 55.4797H98.0156L95.2076 51.064H91.949V55.4797H90.3933V55.471ZM91.9533 49.6687H95.68C96.109 49.6687 96.4816 49.5517 96.798 49.3133C97.1143 49.075 97.3656 48.763 97.5433 48.3687C97.721 47.9743 97.812 47.554 97.812 47.1033C97.812 46.6397 97.708 46.215 97.5 45.8293C97.292 45.4437 97.019 45.1317 96.6853 44.902C96.3516 44.6723 95.979 44.5553 95.576 44.5553H91.9533V49.6687Z"
				fill="#383838"
			/>
			<path
				d="M101.777 55.471V43.173H106.037C107.354 43.173 108.455 43.446 109.339 43.9963C110.223 44.5467 110.881 45.2833 111.323 46.215C111.761 47.1467 111.982 48.1737 111.982 49.309C111.982 50.557 111.739 51.6403 111.254 52.5677C110.769 53.4907 110.08 54.21 109.191 54.717C108.303 55.224 107.25 55.4797 106.037 55.4797H101.777V55.471ZM110.405 49.3047C110.405 48.3817 110.236 47.5627 109.893 46.8433C109.551 46.1283 109.057 45.5693 108.411 45.162C107.766 44.759 106.973 44.5553 106.037 44.5553H103.333V54.0843H106.037C106.986 54.0843 107.779 53.872 108.429 53.4517C109.074 53.0313 109.564 52.4593 109.902 51.7357C110.236 51.0163 110.405 50.206 110.405 49.3047Z"
				fill="#383838"
			/>
			<path d="M114.179 55.471V43.173H115.739V55.4753H114.179V55.471Z" fill="#383838" />
			<path
				d="M120.246 46.046V55.471H118.686V43.173H120.003L127.521 52.7887V43.1903H129.081V55.4753H127.66L120.246 46.046Z"
				fill="#383838"
			/>
			<path
				d="M135.768 43.173H137.085L142.107 55.4753H140.443L139.065 51.87H133.774L132.388 55.4753H130.724L135.768 43.173ZM138.602 50.6567L136.426 44.9757L134.242 50.6567H138.602Z"
				fill="#383838"
			/>
			<path
				d="M142.506 49.218C142.506 48.4683 142.636 47.7317 142.896 47.008C143.156 46.2843 143.537 45.63 144.04 45.032C144.543 44.4383 145.158 43.9617 145.886 43.6107C146.614 43.2597 147.442 43.082 148.365 43.082C149.461 43.082 150.401 43.3333 151.19 43.836C151.974 44.3387 152.559 44.9843 152.941 45.7687L151.71 46.566C151.467 46.046 151.16 45.6343 150.783 45.3267C150.406 45.019 150.003 44.8023 149.569 44.6767C149.136 44.551 148.711 44.486 148.295 44.486C147.615 44.486 147.008 44.6247 146.484 44.902C145.96 45.1793 145.518 45.5477 145.158 46.0113C144.798 46.475 144.53 46.9907 144.352 47.5627C144.174 48.1347 144.083 48.711 144.083 49.2873C144.083 49.9243 144.192 50.5353 144.404 51.1247C144.616 51.714 144.915 52.234 145.297 52.6847C145.678 53.1353 146.129 53.495 146.649 53.7593C147.169 54.0237 147.732 54.158 148.347 54.158C148.776 54.158 149.214 54.0843 149.665 53.9327C150.115 53.781 150.536 53.547 150.93 53.2307C151.324 52.9143 151.636 52.4983 151.866 51.9913L153.166 52.702C152.91 53.313 152.52 53.833 151.987 54.262C151.454 54.691 150.865 55.0117 150.211 55.2327C149.556 55.4537 148.915 55.562 148.278 55.562C147.433 55.562 146.662 55.38 145.955 55.016C145.249 54.652 144.642 54.1667 144.127 53.56C143.611 52.9533 143.212 52.273 142.931 51.5147C142.649 50.7563 142.506 49.9893 142.506 49.218Z"
				fill="#383838"
			/>
			<path d="M155.12 55.471V43.173H156.68V55.4753H155.12V55.471Z" fill="#383838" />
			<path
				d="M158.756 53.5297C158.977 53.6813 159.28 53.82 159.666 53.9543C160.052 54.0887 160.511 54.1537 161.044 54.1537C161.586 54.1537 162.032 54.067 162.379 53.8893C162.725 53.7117 162.99 53.443 163.176 53.0833C163.362 52.7237 163.488 52.2643 163.557 51.701C163.627 51.142 163.661 50.4747 163.661 49.712V43.173H165.221V49.374C165.221 50.2797 165.174 51.1073 165.083 51.8657C164.992 52.6197 164.805 53.2783 164.528 53.833C164.251 54.3877 163.839 54.8167 163.289 55.1113C162.738 55.4103 162.01 55.5577 161.096 55.5577C160.52 55.5577 160.008 55.4927 159.571 55.367C159.133 55.2413 158.726 55.0507 158.357 54.795L158.756 53.5297Z"
				fill="#383838"
			/>
			<path
				d="M171.474 43.173H172.792L177.814 55.4753H176.15L174.772 51.87H169.481L168.094 55.4753H166.43L171.474 43.173ZM174.308 50.6567L172.133 44.9757L169.949 50.6567H174.308Z"
				fill="#383838"
			/>
			<path
				d="M96.9193 86.7403V70.4167L90.5449 82.5717H87.8496L81.4753 70.4167V86.7403H76.6393V61.867H81.8263L89.1843 76.0197L96.6116 61.867H101.76V86.7403H96.9193V86.7403Z"
				fill="#383838"
			/>
			<path
				d="M113.702 61.867H118.608L127.751 86.7403H122.776L120.709 80.5393H111.579L109.499 86.7403H104.524L113.702 61.867ZM119.574 77.142L116.151 66.8763L112.71 77.142H119.574Z"
				fill="#383838"
			/>
			<path
				d="M130.481 86.7403V61.867H141.483C142.627 61.867 143.685 62.101 144.655 62.569C145.626 63.037 146.467 63.6653 147.177 64.4627C147.888 65.2557 148.438 66.144 148.824 67.1233C149.21 68.1027 149.4 69.108 149.4 70.135C149.4 71.1837 149.218 72.1977 148.859 73.164C148.495 74.1347 147.979 74.9797 147.299 75.7033C146.623 76.427 145.83 76.986 144.915 77.3847L150.592 86.7403H145.266L140.153 78.403H135.317V86.7403H130.481ZM135.317 74.165H141.379C141.986 74.165 142.523 73.983 142.991 73.6233C143.459 73.2637 143.832 72.7783 144.114 72.1673C144.395 71.5607 144.534 70.8847 144.534 70.135C144.534 69.342 144.369 68.6443 144.044 68.0507C143.715 67.457 143.303 66.9803 142.801 66.6337C142.298 66.2827 141.756 66.1093 141.171 66.1093H135.321V74.165H135.317Z"
				fill="#383838"
			/>
			<path
				d="M153.777 86.7403V61.867H158.613V73.6883L169.052 61.8323H174.378L164.849 72.8823L174.971 86.7403H169.507L161.763 76.0067L158.609 79.3043V86.7403H153.777V86.7403Z"
				fill="#383838"
			/>
			<path
				d="M186.394 86.9137C184.574 86.9137 182.919 86.5627 181.437 85.8607C179.955 85.1587 178.681 84.2097 177.619 83.005C176.557 81.8004 175.734 80.4484 175.149 78.9404C174.564 77.4324 174.274 75.8854 174.274 74.2994C174.274 72.6397 174.581 71.058 175.201 69.5544C175.821 68.0464 176.67 66.7074 177.758 65.5244C178.845 64.3457 180.128 63.4097 181.61 62.7207C183.092 62.0317 184.721 61.6894 186.498 61.6894C188.296 61.6894 189.934 62.0534 191.421 62.777C192.903 63.5007 194.172 64.4714 195.221 65.6847C196.27 66.898 197.089 68.2544 197.674 69.7494C198.259 71.2444 198.549 72.787 198.549 74.373C198.549 76.0067 198.246 77.5797 197.639 79.0834C197.032 80.587 196.183 81.9347 195.1 83.1134C194.012 84.292 192.729 85.2194 191.247 85.8997C189.761 86.5757 188.145 86.9137 186.394 86.9137ZM179.175 74.3037C179.175 75.3784 179.339 76.4184 179.664 77.4237C179.989 78.429 180.466 79.3217 181.081 80.106C181.701 80.8904 182.459 81.5057 183.361 81.9607C184.258 82.4157 185.28 82.6454 186.424 82.6454C187.594 82.6454 188.63 82.407 189.544 81.926C190.454 81.4494 191.213 80.8037 191.824 79.9977C192.43 79.1917 192.894 78.2947 193.206 77.3024C193.522 76.31 193.678 75.3134 193.678 74.308C193.678 73.2334 193.509 72.202 193.171 71.2097C192.833 70.2174 192.352 69.329 191.737 68.549C191.117 67.7647 190.359 67.1494 189.462 66.6944C188.561 66.2394 187.551 66.0097 186.433 66.0097C185.241 66.0097 184.197 66.248 183.3 66.729C182.399 67.21 181.649 67.8427 181.042 68.64C180.436 69.433 179.972 70.3214 179.66 71.3007C179.335 72.2714 179.175 73.2767 179.175 74.3037Z"
				fill="#383838"
			/>
			<path
				d="M203.875 61.867L210.6 80.9943L217.256 61.867H222.335L212.91 86.7403H208.251L198.792 61.867H203.875V61.867Z"
				fill="#383838"
			/>
			<path d="M225 86.7403V61.867H229.836V86.7403H225Z" fill="#383838" />
			<path
				d="M233.51 74.0913C233.51 72.5747 233.783 71.084 234.334 69.6237C234.884 68.1633 235.681 66.833 236.734 65.6283C237.787 64.4237 239.07 63.4703 240.587 62.7553C242.103 62.0447 243.832 61.685 245.769 61.685C248.057 61.685 250.038 62.1877 251.706 63.193C253.374 64.1983 254.618 65.507 255.437 67.1147L251.723 69.6713C251.303 68.7397 250.753 67.9943 250.077 67.4483C249.401 66.898 248.677 66.5167 247.906 66.2913C247.134 66.0703 246.376 65.9577 245.631 65.9577C244.417 65.9577 243.36 66.2047 242.459 66.6943C241.557 67.184 240.808 67.8253 240.201 68.6227C239.594 69.4157 239.144 70.304 238.853 71.2833C238.559 72.2627 238.416 73.2463 238.416 74.2257C238.416 75.322 238.589 76.3793 238.94 77.3977C239.291 78.4117 239.785 79.313 240.431 80.093C241.072 80.8773 241.843 81.4927 242.745 81.9477C243.642 82.4027 244.63 82.6323 245.704 82.6323C246.476 82.6323 247.256 82.5023 248.053 82.2467C248.846 81.991 249.583 81.5793 250.259 81.0203C250.935 80.4613 251.463 79.7377 251.836 78.8493L255.797 81.1243C255.307 82.3637 254.505 83.4123 253.396 84.279C252.287 85.1413 251.043 85.7957 249.665 86.242C248.287 86.684 246.922 86.9093 245.566 86.9093C243.789 86.9093 242.168 86.541 240.695 85.8043C239.222 85.0677 237.956 84.0883 236.895 82.862C235.833 81.6357 235.001 80.2577 234.407 78.728C233.809 77.207 233.51 75.66 233.51 74.0913ZM247.316 59.7653L244.305 58.8553L246.198 54.6867H250.579L247.316 59.7653Z"
				fill="#383838"
			/>
			<path
				d="M52.5157 75.2353C49.283 80.8903 34.2377 89.8213 34.0861 89.9123C33.8391 90.0597 33.449 90.1463 33.0417 90.1463C32.6344 90.1463 32.24 90.0597 31.9887 89.908C31.837 89.817 16.926 80.8947 13.6934 75.2353C11.024 70.5597 10.9287 55.7007 11.115 50.3707C11.1367 49.777 11.687 48.9623 12.2287 48.7197L31.733 40.0747C32.045 39.936 32.5304 39.8537 33.0287 39.8537C33.527 39.8537 34.0124 39.936 34.3244 40.0747L53.8287 48.7197C54.3747 48.9623 54.9337 49.7813 54.9597 50.3793C55.1894 55.7093 55.1894 70.5597 52.5157 75.2353Z"
				fill="url(#paint0_linear)"
			/>
			<path
				d="M33.0027 67.7863H33.111H33.0027ZM42.887 70.98C42.0984 72.8 41.236 74.425 40.456 75.5603C39.7627 76.57 39.572 76.6783 39.2167 76.999C39.169 77.0423 39.1257 77.0207 39.104 76.9687C38.792 76.0977 38.506 75.049 38.2027 73.9353C37.7174 72.1543 37.2147 70.317 36.5214 68.861C35.6114 66.9543 34.4934 66.027 33.111 66.027H33.0027C31.6637 66.027 30.5717 66.9457 29.666 68.8393C28.9727 70.278 28.4527 72.1197 27.9457 73.905C27.6294 75.0187 27.3304 76.0717 27.0097 76.9643C26.9967 77.0033 26.975 77.09 26.884 77.064C26.6674 76.999 26.3337 76.609 25.6274 75.595C24.8474 74.477 23.9894 72.8693 23.205 71.071C21.437 67.0107 20.3407 62.7033 20.3407 59.8303C20.3407 56.4417 22.737 52.936 26.7454 52.936C27.781 52.936 29.1547 53.417 30.42 54.2187C30.602 54.3357 30.8664 54.5393 31.1264 54.7343C31.2304 54.8167 31.3344 54.8947 31.4297 54.9683C31.7417 55.2023 32.1664 55.51 32.669 55.8047C32.9334 55.9607 33.176 56.0993 33.4447 56.2163C34.5324 56.68 35.685 56.914 36.8724 56.914C38.0944 56.914 40.612 56.394 40.612 56.1167C40.612 55.926 37.6394 56.2857 35.49 55.458C35.1477 55.328 34.4544 55.0983 34.4544 55.0117C34.4544 54.9467 34.515 54.8903 34.58 54.8513C34.7317 54.7603 34.905 54.6563 35.1304 54.5307C36.348 53.859 38.0164 52.9403 39.364 52.9403C43.3724 52.9403 45.7687 56.446 45.7687 59.8347C45.7687 62.6123 44.6637 66.8893 42.887 70.98Z"
				fill="white"
			/>
			<path
				d="M61.4163 47.9527C61.3383 46.0893 59.995 44.1177 58.2877 43.3593L35.7197 33.358C34.9657 33.0243 33.9907 32.838 32.981 32.838C31.967 32.838 30.9963 33.02 30.2423 33.358L7.67434 43.3593C5.96701 44.1177 4.62801 46.0937 4.56301 47.9613C4.52834 48.9017 3.79601 71.0927 7.94734 78.364C12.0857 85.605 29.471 96.0137 30.2077 96.4557C30.9747 96.9107 31.9627 97.162 32.994 97.162C34.0167 97.162 35.0003 96.915 35.7587 96.4643C36.504 96.0267 54.0497 85.6093 58.188 78.364C62.3393 71.0883 61.4553 48.893 61.4163 47.9527ZM55.523 76.843C51.7833 83.3863 34.372 93.7213 34.1987 93.8253C33.9127 93.9943 33.462 94.0983 32.9897 94.0983C32.5173 94.0983 32.0623 93.9943 31.772 93.821C31.5987 93.717 14.3433 83.3907 10.6037 76.843C7.51401 71.435 7.40134 54.2403 7.62234 48.0697C7.64834 47.385 8.28534 46.4403 8.91367 46.1587L31.4817 36.1573C31.8413 35.997 32.4047 35.9017 32.981 35.9017C33.5573 35.9017 34.1163 35.997 34.4803 36.1573L57.0483 46.1587C57.681 46.436 58.3267 47.3893 58.357 48.0783C58.6127 54.249 58.6127 71.435 55.523 76.843Z"
				fill="url(#paint1_linear)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear"
					x1="49.9094"
					y1="44.6649"
					x2="16.2299"
					y2="78.3443"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#1E9BA1" />
					<stop offset="0.2026" stopColor="#1D96A0" />
					<stop offset="0.443" stopColor="#1B889B" />
					<stop offset="0.7026" stopColor="#187094" />
					<stop offset="0.9741" stopColor="#134F8A" />
					<stop offset="1" stopColor="#124B89" />
				</linearGradient>
				<linearGradient
					id="paint1_linear"
					x1="54.6637"
					y1="39.2938"
					x2="11.3702"
					y2="82.5873"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#1E9BA1" />
					<stop offset="0.2026" stopColor="#1D96A0" />
					<stop offset="0.443" stopColor="#1B889B" />
					<stop offset="0.7026" stopColor="#187094" />
					<stop offset="0.9741" stopColor="#134F8A" />
					<stop offset="1" stopColor="#124B89" />
				</linearGradient>
			</defs>
		</svg>
	)
}
