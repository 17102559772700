import { useEffect, useState } from "react"
import { ContentWrapper, Heading } from "./Form"
import moment from "moment"
import styled from "@emotion/styled"

export default function PaymentsBox({ generalPayments, payments }) {
	let [filteredPayments, setFilteredPayments] = useState(null)
	let [isVisible, setIsVisible] = useState(false)
	let [activePayment, setActivePayment] = useState(null)

	useEffect(() => {
		setActivePayment(generalPayments[0])
	}, [])

	useEffect(() => {
		let filtered = payments?.filter((item) => item.ID_plcgen === activePayment?.ID_plcgen)
		setFilteredPayments(filtered)
	}, [activePayment])

	return (
		<ContentWrapper>
			<HeadWrapper>
				<Heading>Plaćanja:</Heading>
				<DropButton
					onClick={() => {
						setIsVisible(!isVisible)
					}}
				>
					{moment(activePayment?.Date_start).format("LL")}
					<svg
						width="8"
						height="6"
						viewBox="0 0 8 6"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M1 1L4 4L7 1" stroke="black" strokeWidth="2" strokeLinecap="round" />
					</svg>
				</DropButton>

				{isVisible && (
					<Dropdown>
						{generalPayments?.map((payment) => (
							<ListItem
								key={payment.ID_plcgen}
								isActive={payment.ID_plcgen === activePayment.ID_plcgen}
								onClick={() => {
									setIsVisible(false)
									setActivePayment(payment)
								}}
							>
								<span>{moment(payment.Date_start).format("LL")}</span>

								<svg
									width="11"
									height="9"
									viewBox="0 0 11 9"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path d="M1 4L4 7L10 1" stroke="black" strokeWidth="2" strokeLinecap="round" />
								</svg>
							</ListItem>
						))}
					</Dropdown>
				)}
			</HeadWrapper>

			{filteredPayments?.map((payment) => (
				<Row key={payment.ID}>
					<Left>
						<Type>{payment.vrsta}</Type>
						<Date>{moment(payment.datum).format("LL")}</Date>
					</Left>
					{payment.eur && (
						<Amount isGreen={payment.placanje === 1}>
							{"€ " + payment.eur.toString().replace(/-/, "")}
						</Amount>
					)}
				</Row>
			))}
		</ContentWrapper>
	)
}

let Row = styled.div`
	display: flex;
	background: #f5f5f5;
	padding: 12px;
	border-radius: 6px;
	align-items: center;
	margin-bottom: 12px;

	&:last-of-type {
		margin-bottom: 0;
	}
`

let HeadWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 24px;
	position: relative;

	h4 {
		margin-bottom: 0;
	}
`

let Type = styled.div`
	font-size: 14px;
	font-weight: 700;
	color: var(--dark);
	margin-bottom: 4px;
`

let Amount = styled.div`
	display: flex;
	padding: 8px;
	background: #befad7;
	border-radius: 6px;
	font-weight: 600;
	flex-shrink: 0;
	color: ${(props) => (props.isGreen ? "#0c542b" : "#70560f")};
	background: ${(props) => (props.isGreen ? "#befad7" : "#fffb96")};
`

let Left = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
`

let Date = styled.div`
	font-size: 12px;
	color: #9f9f9f;
`

let Dropdown = styled.div`
	position: absolute;
	right: -1px;
	top: -1px;
	z-index: 11;
	display: flex;
	flex-direction: column;
	min-width: 160px;
	border-radius: 6px;
	overflow: hidden;
	color: var(--dark);
	border: 1px solid #24b7be;
	box-shadow: 0 0 0 2px rgb(30 155 161 / 25%), 0 8px 14px 0 rgb(0 0 0 / 15%);
`

let ListItem = styled.button`
	display: flex;
	border: none;
	outline: none;
	background: #fff;
	cursor: pointer;
	padding: 8px 16px;
	min-height: 40px;
	align-items: center;
	justify-content: space-between;

	svg {
		display: ${(props) => (props.isActive ? "block" : "none")};
		margin-left: 8px;

		path {
			stroke: var(--dark);
		}
	}

	&:hover {
		background: #f6f6f6;
	}
`

let DropButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	outline: none;
	min-height: 40px;
	border-radius: 8px;
	font-size: 14px;
	font-weight: 500;
	padding: 4px 16px;
	cursor: pointer;
	transition: all 300ms;
	color: var(--dark);
	border: 1px solid #24b7be;
	box-shadow: 0 0 0 2px rgba(30, 155, 161, 0.25);
	position: relative;
	z-index: 1;
	background: transparent;
	font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
		"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

	svg {
		display: block;
		margin-left: 8px;

		path {
			stroke: var(--dark);
		}
	}
`
