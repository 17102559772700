import { Background, FullContent, Input, InputWrapper, Label, SubmitButton } from "./Form"
import axios from "axios"
import { useState, useEffect } from "react"
import styled from "@emotion/styled"
import Logo from "./Logo"
import AuthService from "../services/auth.service"

let FIRST_TIME = "https://cocky-kowalevski-87b5ff.netlify.app/.netlify/functions/server/first/"

export default function NewPassword({
	userId,
	setIsFirstTime,
	setIsLoading,
	getAppointments,
	getPayments,
	getGeneralPayments,
}) {
	let [password, setPassword] = useState("")
	let [repeatPassword, setRepeatPassword] = useState("")
	let [isValid, setIsValid] = useState(false)

	let handlePasswordUpdate = () => {
		setIsLoading(true)

		axios
			.post(FIRST_TIME + userId, {
				newPassword: password,
				userId,
			})
			.then((response) => {
				AuthService.login(userId, password).then((user) => {
					getAppointments(user)
					getPayments(user)
					getGeneralPayments(user)
					setIsFirstTime(user.data.first_login)
					setIsLoading(false)
					setIsFirstTime(0)
				})
			})
	}

	useEffect(() => {
		validatePassword()
	}, [password, repeatPassword])

	let validatePassword = () => {
		setIsValid(false)

		if (password.length < 6 || repeatPassword.length < 6) {
			return
		}

		if (password !== repeatPassword) {
			return
		}

		setIsValid(true)
	}

	return (
		<Background>
			<FullContent>
				<Logo />
				<Content>
					<InputWrapper>
						<Input
							type="password"
							value={password}
							minlength={6}
							onChange={(e) => {
								setPassword(e.currentTarget.value)
							}}
						/>
						<Label isActive={!!password}>Unesite novu lozinku</Label>
					</InputWrapper>
					<InputWrapper>
						<Input
							type="password"
							minlength={6}
							value={repeatPassword}
							onChange={(e) => {
								setRepeatPassword(e.currentTarget.value)
							}}
						/>
						<Label isActive={!!repeatPassword}>Ponovi lozinku</Label>
					</InputWrapper>
					<InfoBox>Lozinka mora imati najmanje 6 karaktera.</InfoBox>
					<InfoBox>Prilikom sledećeg pristupanja, koristite novu lozinku.</InfoBox>
					<SubmitButton type="submit" disabled={!isValid} onClick={handlePasswordUpdate}>
						Sačuvaj Lozinku
					</SubmitButton>
				</Content>
			</FullContent>
		</Background>
	)
}

let InfoBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(207, 207, 207, 0.25);
	color: #4a4a4a;
	font-size: 14px;
	padding: 12px;
	line-height: 1.51;
	font-weight: 600;
	margin-bottom: 20px;
	border-radius: 8px;
`

let Content = styled.form`
	background: #fff;
	max-width: 360px;
	margin: 0 auto;
	padding: 24px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	border-radius: 16px;
	box-shadow: 0 1px 2px 0 rgba(9, 30, 66, 0.25);

	> div {
		width: 100%;
	}
`
